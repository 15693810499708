.w-dyn-item {

border-radius: 30px;
border: 5px solid #4a3aff;
padding: 25px;
margin-top:50px;
}

.orange-button {
  color: black!important;
  padding: 25px;
  margin: 50px;
  font-size: 30px!important;
  background-color:orange!important;
}